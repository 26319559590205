import React, { useEffect } from "react"
import { Box } from "grommet"
import { App, LoadingWithText, EmptyData } from "../components"
import { useIntl, useAuth0, useApi } from "../hooks"
import { reportError } from "../utils"
const Page = () => {
  const intl = useIntl()
  const auth0 = useAuth0()
  const { isAuthenticated, loading } = auth0

  const { data, isValidating, error } = useApi(
    isAuthenticated && loading === false ? `/user/apps` : null
  )
  useEffect(() => {
    if (error) {
      reportError(error)
    }
  }, [error])

  return (
    <Box>
      {(loading || (isValidating && !data)) && (
        <LoadingWithText
          text={intl.formatMessage({
            id: "loading_apps",
            defaultMessage: "Loading your apps...",
          })}
        ></LoadingWithText>
      )}
      {data && data.data && data.data.items && data.data.items.length === 0 && (
        <EmptyData></EmptyData>
      )}
      {data &&
        data.data &&
        data.data.items &&
        data.data.items.map(item => <App key={item.provider} {...item} />)}
    </Box>
  )
}

export default Page
