import React from "react"
import { Box, Heading } from "grommet"
import { useIntl } from "../utils"
import { Layout, SEO, Protected, FormattedMessage } from "../components"
import { MyApps } from "../containers"
const Page = () => {
  const intl = useIntl()
  return (
    <Layout>
      <SEO title={intl.formatMessage({ id: "hello" })} />
      <Box pad={{ top: "medium" }}>
        <Heading>
          <FormattedMessage
            id="my_apps"
            defaultMessage="My Apps"
          ></FormattedMessage>
        </Heading>
        <Protected component={MyApps} />
      </Box>
    </Layout>
  )
}
export default Page
