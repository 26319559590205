import React, { useEffect, useState } from "react"
import { useIntl } from "../hooks"
import {
  getQuery,
  navigate,
  fetchApi,
  reportError,
  decodeAppState,
  replace,
} from "../utils"
import { ErrorInfo, FormattedMessage, LoadingWithText } from "../components"
import { debug } from "../utils/debug"
const Container = ({ provider: propsProvder }) => {
  let { provider, ...restQuery } = getQuery()
  if (propsProvder) {
    provider = propsProvder
  }
  const { formatMessage } = useIntl()
  const [errorInfo, setErrorInfo] = useState()
  useEffect(() => {
    const init = async () => {
      // get access token
      let sessionResult
      try {
        sessionResult = await fetchApi(`/session/${provider}`)
      } catch (error) {
        setErrorInfo(error.message)
        reportError(error)
        return
      }
      // check app_state
      let action = "create"
      let targetUrl = ""
      let payload = {}
      if (sessionResult.data.appState) {
        let appState = {}
        try {
          appState = decodeAppState(sessionResult.data.appState)
          debug("appState %j", appState)
          if (appState.action) {
            action = appState.action
          }
          if (appState.targetUrl) {
            targetUrl = appState.targetUrl
          }
          if (appState.payload) {
            payload = appState.payload
          }
        } catch (error) {
          reportError(error)
        }
      }
      try {
        let connectionData
        // if (action === "reconnect") {
        //   connectionData = await fetchApi(
        //     `/connections/${payload.connectionId}`,
        //     {
        //       method: "PATCH",
        //       body: {
        //         query: restQuery,
        //       },
        //     }
        //   )
        // } else {
        //   connectionData = await fetchApi("/connections", {
        //     body: {
        //       provider: provider,
        //       query: restQuery,
        //     },
        //   })
        // }
        // if (targetUrl) {
        //   if (targetUrl.startsWith("http")) {
        //     replace(targetUrl)
        //   } else {
        //     navigate(targetUrl, {
        //       replace: true,
        //     })
        //   }
        // } else {
        //   navigate(`/connections/${connectionData.data.connectionId}`, {
        //     replace: true,
        //   })
        // }
      } catch (error) {
        if (error.status === 409) {
          navigate(`/connections/${error.data.data.connectionId}`, {
            replace: true,
          })
        } else {
          setErrorInfo(error.message)
          reportError(error)
        }
      }
    }
    init()
  }, [provider, restQuery])
  if (!provider) {
    return (
      <ErrorInfo>
        <FormattedMessage
          id="error_miss_param_provider"
          defaultMessage="Some problem occured, we cannot found a valid provider at here, please try again."
        ></FormattedMessage>
      </ErrorInfo>
    )
  }
  if (errorInfo) {
    return <ErrorInfo>{errorInfo}</ErrorInfo>
  }
  return (
    <LoadingWithText
      text={formatMessage({
        id: "connecting",
        defaultMessage: "Connecting...",
      })}
    ></LoadingWithText>
  )
}

export default Container
