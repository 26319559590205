import React, { useEffect, useState } from "react"
import { Box, Button, Heading } from "grommet"
import {
  FormattedMessage,
  Client,
  LoadingWithText,
  EmptyData,
  Spinner,
  toast,
} from "../components"
import { Add } from "grommet-icons"
import { useIntl, useAuth0, useApi } from "../hooks"
import { reportError, fetchApi, navigate } from "../utils"
const Page = () => {
  const intl = useIntl()
  const auth0 = useAuth0()
  const { isAuthenticated, loading } = auth0
  const [createdLoading, setCreatedLoading] = useState(false)
  const { data, isValidating, error } = useApi(
    isAuthenticated && loading === false ? `/user/clients` : null
  )
  useEffect(() => {
    if (error) {
      reportError(error)
    }
  }, [error])
  const handleCreateConnection = async () => {
    setCreatedLoading(true)
    try {
      const clientsData = await fetchApi(`/clients`, {
        method: "POST",
        body: {
          type: "personal_client_credentials",
          scopes: [
            "read:clients",
            "write:clients",
            "read:connections",
            "write:connections",
            "read:users",
          ],
          name: "client",
        },
      })
      setCreatedLoading(false)
      if (clientsData && clientsData.data && clientsData.data.clientId) {
        navigate(`/clients/${clientsData.data.clientId}`)
      }
    } catch (error) {
      setCreatedLoading(false)
      toast(error.message)
      reportError(error)
    }
  }
  return (
    <Box pad={{ top: "medium" }}>
      <Box>
        <Button
          disabled={createdLoading}
          icon={createdLoading ? <Spinner /> : <Add></Add>}
          onClick={handleCreateConnection}
          label={intl.formatMessage({
            id: "create_new_client",
            defaultMessage: "Create new client",
          })}
        ></Button>
      </Box>
      <Heading>
        <FormattedMessage
          id="my_clients"
          defaultMessage="My Clients"
        ></FormattedMessage>
      </Heading>
      <Box>
        {(loading || (isValidating && !data)) && (
          <LoadingWithText
            text={intl.formatMessage({
              id: "loading_clients",
              defaultMessage: "Loading your clients...",
            })}
          ></LoadingWithText>
        )}
        {data &&
          data.data &&
          data.data.items &&
          data.data.items.length === 0 && <EmptyData></EmptyData>}
        {data &&
          data.data &&
          data.data.items &&
          data.data.items.map(item => <Client key={item.clientId} {...item} />)}
      </Box>
    </Box>
  )
}

export default Page
