import React from "react"
import { Box } from "grommet"
import { useApi, useIntl } from "../hooks"
import { ErrorInfo, LoadingWithText } from "../components"
const Container = ({ clientId }) => {
  const { formatMessage } = useIntl()
  const { data, error, isValidating: loading } = useApi(`/clients/${clientId}`)
  if (error) {
    return <ErrorInfo>{error.message}</ErrorInfo>
  } else if (data) {
    return (
      <Box pad="medium">
        {loading &&
          !data(
            <LoadingWithText
              text={formatMessage({
                id: "loading_client",
                defaultMessage: "Loading client...",
              })}
            ></LoadingWithText>
          )}
        <pre>{JSON.stringify(data.data, null, 2)}</pre>
      </Box>
    )
  } else {
    return null
  }
}

export default Container
