import React, { useEffect } from "react"
import { Box, Heading } from "grommet"
import {
  FormattedMessage,
  Connection,
  LoadingWithText,
  EmptyData,
} from "../components"
import { useIntl, useAuth0, useApi } from "../hooks"
import { reportError } from "../utils"
const Page = () => {
  const intl = useIntl()
  const auth0 = useAuth0()
  const { isAuthenticated, loading } = auth0
  const {
    data: connectionsData,
    isValidating: connectionsLoading,
    error,
  } = useApi(isAuthenticated && loading === false ? `/user/connections` : null)
  useEffect(() => {
    if (error) {
      reportError(error)
    }
  }, [error])

  return (
    <Box>
      <Heading>
        <FormattedMessage
          id="connections"
          defaultMessage="Connections"
        ></FormattedMessage>
      </Heading>
      <Box>
        {(loading || (connectionsLoading && !connectionsData)) && (
          <LoadingWithText
            text={intl.formatMessage({
              id: "loading_connections",
              defaultMessage: "Loading your connections...",
            })}
          ></LoadingWithText>
        )}
        {connectionsData &&
          connectionsData.data &&
          connectionsData.data.items &&
          connectionsData.data.items.length === 0 && <EmptyData></EmptyData>}
        {connectionsData &&
          connectionsData.data &&
          connectionsData.data.items &&
          connectionsData.data.items.map(item => (
            <Connection key={item.connectionId} {...item} />
          ))}
      </Box>
    </Box>
  )
}

export default Page
